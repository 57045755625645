import cx from 'classnames';
import * as React from 'react';

import RichText from '../RichText';

import * as styles from './CallOut.module.scss';

export const CallOut = ({ icon, children, newitem, align = 'left' }) => (
  <div className={styles.root}>
    <div className={cx(styles.callOutIcon, styles[align])}>{icon}</div>
    <div className={styles.styledCallOut}>
      {children && !React.isValidElement(children) && (
        <RichText dangerouslySetInnerHTML={{ __html: children }} />
      )}
      {children && React.isValidElement(children) && children}
      <img alt="" className={styles.callOutImage} src="/icon.png" />
      {newitem && <div className={styles.newTag} />}
    </div>
  </div>
);
