import * as React from 'react';
import { graphql } from 'gatsby';

import CallOut from '../components/CallOut';
import Container from '../components/Container';
import Layout from '../components/Layout';

import { Cake, Cookie, Cupcake, Gift } from '../icons';

const makeCopy = item => `
  <q>${item.copy.copy}</q>
  <p><em>- ${item.author}</em></p>
`;

const IconArray = [
  <Cake key="cake" />,
  <Cookie key="cookie" />,
  <Cupcake key="cupcake" />,
  <Gift key="gift" />,
];

function* GetIcon() {
  let index = 0;

  while (true) {
    yield index++;
    if (index === 4) {
      index = 0;
    }
  }
}

const ReviewsPage = ({ data }) => {
  const { title, list } = data.contentfulReviews;
  const generator = GetIcon();
  return (
    <Layout pagetitle="Reviews" title={title}>
      <Container>
        {list.map((item, idx) => (
          <div key={item.id}>
            <CallOut
              align={idx % 2 === 0 ? 'left' : 'right'}
              icon={IconArray[generator.next().value]}
            >
              {makeCopy(item)}
            </CallOut>
          </div>
        ))}
      </Container>
    </Layout>
  );
};

export default ReviewsPage;

export const pageQuery = graphql`
  query ReviewQuery {
    contentfulReviews {
      title
      list {
        id
        copy {
          copy
        }
        author
      }
    }
  }
`;
