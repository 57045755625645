// extracted by mini-css-extract-plugin
export var callOutIcon = "CallOut-module--callOutIcon--giDeR";
export var callOutImage = "CallOut-module--callOutImage--ZZaaK";
export var color1 = "CallOut-module--color1--ieP8V";
export var color2 = "CallOut-module--color2--X+oJM";
export var color3 = "CallOut-module--color3--jJ6lz";
export var left = "CallOut-module--left--0+NiL";
export var newTag = "CallOut-module--newTag--4VJP4";
export var right = "CallOut-module--right--cQg02";
export var root = "CallOut-module--root---DJwu";
export var styledCallOut = "CallOut-module--styledCallOut--7Ty9J";